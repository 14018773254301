.imageContainer {
    width: 100%;
    padding: 0 2%;
    margin-top: 110px;
    min-width: var(--min-supported-width);
    box-sizing: border-box;
}

.image {
    width: 100%;
}