@import './Styles/Global/Layout.css';
@import './Styles/Global/Typography.css';
@import './Styles/Global/ColorScheme.css';

body {
  margin: 0;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, メイリオ, Meiryo,
      "ＭＳ Ｐゴシック", "MS PGothic", "ＭＳ ゴシック" , "MS Gothic",
      "Noto Sans CJK JP", TakaoPGothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--link-blue);
}

/* Overwrite default button styling */
button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Outline for accessibility */
button:focus, input[type="submit"]:focus, input[type="reset"]:focus {
  outline: 2px dotted #333333;
}