.headerContainer {
    margin-top: 160px;
}

.headerContainer h2 {
    color: var(--theme-navy);
    background: -webkit-linear-gradient(0deg, var(--theme-green), var(--theme-purple), var(--theme-orange), var(--theme-pink));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
}

.headerContainer p {
    color: var(--theme-gray-scale-5);
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    padding: 15px 0;
}

@media (max-width: 991px) {
    .headerContainer {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .headerContainer {
        margin-top: 30px;
    }
}