.componentContainer {
    padding: 0;
    min-width: var(--min-supported-width);
    box-sizing: border-box;
}

.groupContainer {
    display: flex;
    flex-wrap: wrap;
}

.linkContainer {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 1.8rem;
}

.link {
    text-decoration: none !important;
}

.linkText {
    font-size: var(--enlarged-body-text-font-size);
    line-height: var(--enlarged-body-text-line-height);
}

.icon {
    margin: 0 10px;
}

.headerText {
    margin-top: 0;
    margin-bottom: 10px;
    padding-top: 16px;
    font-size: var(--small-header-text-font-size);
    line-height: var(--small-header-text-line-height);
    color: var(--theme-gray-scale-6);
    background: -webkit-linear-gradient(0deg, var(--theme-purple), var(--theme-green), var(--theme-pink), var(--theme-navy));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 767px) {
    .componentContainer {
        padding: 0 15px;
        background-color: #FBFBFB;
        border-top: 2px solid #F4F4F4;
        border-bottom: 2px solid #F4F4F4;
        margin-top: 16px;
    }

    .linkContainer {
        flex-basis: 100%;
    }

    .headerText {
        font-size: var(--small-title-text-font-size);
        line-height: var(--small-title-text-font-size);
    }
}

@media (max-width: 555px) {
    .headerText {
        font-size: var(--large-header-text-font-size);
        line-height: var(--large-header-text-line-height);
    }
}