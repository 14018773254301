.container {
    background-color: var(--theme-purple-light);
    padding-bottom: 15px;
    overflow: hidden;
}

.parallaxContainer {
    margin-bottom: 80px;
}

.costContainer {
    padding-top: 50px;
}

.closingHeader, .closingDescLine {
    text-align: center;
    color: var(--theme-purple);
}

.closingHeader {
    font-size: var(--large-header-text-font-size);
    line-height: var(--large-header-text-line-height);
}

.closingDescLine {
    font-weight: bold;
    font-size: var(--small-header-text-font-size);
    line-height: var(--small-header-text-line-height);
}

.closingRemarksContainer {
    box-sizing: border-box;
    min-width: var(--min-supported-width);
    padding: 0 15px;
}

@media (max-width: 767px) {
    .closingHeader {
        font-size: var(--header-text-font-size);
        line-height: var(--header-text-line-height);
    }

    .closingDescLine {
        font-size: var(--body-text-font-size);
        line-height: var(--body-text-line-height);
    }
}