.flexContainer {
    margin-top: 100px;
    display: flex;
}

.flexContainer > * {
    flex-basis: 100%;
}

.contentBox {
    background-color: var(--theme-purple);
    color: var(--theme-gray-scale-14);
    min-width: var(--min-supported-width);
}

.textWrapper {
    padding: 0 50px 30px 20px;
    text-shadow: 1px 1px 0 var(--theme-purple-dark);
}

.titleText {
    color: var(--theme-gray-scale-15);
    margin-bottom: 5px;
    text-shadow: 2px 2px 2px var(--theme-purple-dark);
    font-size: var(--small-title-text-font-size);
    line-height: var(--small-title-text-font-size);
}

.descriptionText {
    font-size: var(--header-text-font-size);
    line-height: var(--header-text-line-height);
    margin-top: 5px;
}

.descriptionSubHeaderText {
    margin-bottom: 0;
    font-size: var(--header-text-font-size);
    line-height: var(--header-text-line-height);
}

@media (max-width: 767px) {
    .flexContainer {
        flex-wrap: wrap;
    }

    .textWrapper {
        padding: 0 15px 30px;
    }
}

@media (max-width: 555px) {
    .flexContainer {
        margin-top: 40px;
    }

    .titleText {
        text-align: center;
        font-size: var(--large-header-text-font-size);
        line-height: var(--large-header-text-line-height);
    }

    .descriptionSubHeaderText {
        font-size: var(--small-header-text-font-size);
        line-height: var(--small-header-text-line-height);
    }

    .descriptionText {
        font-size: var(--small-header-text-font-size);
        line-height: var(--small-header-text-line-height);
    }
}

@media (max-width: 370px) {
    .titleText {
        font-size: var(--header-text-font-size);
        line-height: var(--header-text-line-height);
    }
}