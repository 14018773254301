.charactersContainer {
    display: flex;
    justify-content: center;
    margin-top: -70px;
    margin-bottom: -50px;
}

.beaverContainer, .kumataContainer, .kumataImage, .beaverImage {
    height: 260px;
    width: 260px;
}

.kumataContainer {
    margin-left: -120px;
}

@media (max-width: 555px) {
    .beaverContainer, .kumataContainer, .kumataImage, .beaverImage {
        height: 200px;
        width: 200px;
    }

    .kumataContainer {
        margin-left: -80px;
    }
}