.navContainer {
    position: fixed;
    height: 40px;
    width: 100%;
    bottom: -50px;
    display: flex;
    z-index: 10;
    border-top: 1px solid var(--theme-gray-scale-3);
    transition-duration: 0.5s;
    min-width: var(--min-supported-width);
}

.showNavBar {
    bottom: 0;
}