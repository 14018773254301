.container {
    border-bottom: 15px solid var(--theme-navy);
    border-top: 15px solid var(--theme-navy);
    border-right: 15px solid var(--theme-navy);
    min-width: var(--min-supported-width);
}

.textWrap {
    padding: 0 5px;
}

.thoughtText {
    padding-top: 20px;
    text-align: center;
    color: var(--theme-gray-scale-4);
}

.imageContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
    height: 260px;
}

.characterImage {
    width: 400px;
    height: 400px;
    margin-top: -140px;
}

.solutionText {
    text-align: center;
    font-weight: bold;
    color: var(--theme-navy);
}

@media (max-width: 767px) {
    .container {
        padding-bottom: 20px;
        border: none;
    }
    .characterImage {
        width: 260px;
        height: 260px;
        margin-top: -80px;
    }
    .imageContainer {
        height: 180px;
    }
}