.menuContainer {
    width: 92%;
    max-width: 900px;
    margin: 0 auto;
    min-width: var(--min-supported-width);
}

.banner {
    text-align: center;
    font-size: var(--large-header-text-font-size);
    line-height: var(--large-header-text-line-height);
    padding: 10px 0;
    color: var(--theme-gray-scale-15);
}

.menu {
    width: 100%;
    box-sizing: border-box;
    color: var(--theme-gray-scale-3);
}

.saleHeader {
    display: flex;
    font-size: var(--enlarged-body-text-font-size);
    line-height: var(--enlarged-body-text-line-height);
    padding: 30px 30px 0;
    color: #ea2525;
}

.saleDescText {
    padding-left: 15px;
}

.headerCostContainer {
    width: 100%;
    text-align: center;
    padding: 40px 0 20px;
    font-size: var(--enlarged-body-text-font-size);
    line-height: var(--enlarged-body-text-line-height);
}

.headerCostFee {
    font-size: var(--header-text-font-size);
    /* Line height difference here is intentional */
    line-height: var(--enlarged-body-text-line-height);
    font-weight: bold;
}

.headerCostDiscount {
    color: #85ca12;
    font-weight: bold;
    text-align: center;
    font-size: var(--header-text-font-size);
    /* Line height difference here is intentional */
    line-height: var(--enlarged-body-text-line-height);
}

.specialNote {
    font-size: var(--enlarged-body-text-font-size);
    line-height: var(--enlarged-body-text-line-height);
}

.accentedDividerBar {
    width: 80%;
    height: 2px;
    margin: 0 10%;
}

.menuItem {
    width: 80%;
    margin: 20px 10%;
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--theme-gray-scale-11);
    color: var(--theme-gray-scale-3)
}

.costLineContainer {
    width: 100%;
    display: flex;
    font-size: var(--small-header-text-font-size);
    line-height: var(--small-header-text-line-height);
}

.costDescriptionGroup {
    flex-basis: 50%;
    text-align: right;
}

.costGroup {
    flex-basis: 50%;
    padding-left: 30px;
    text-align: left;
}

.stalePrice {
    color: #700808;
    text-decoration: line-through;
}

.discountedPrice {
    color: #85ca12;
    font-weight: bold;
}

.saleDescription {
    color: var(--theme-gray-scale-6);
    padding-top: 10px;
    font-size: var(--reduced-body-text-font-size);
    line-height: var(--reduced-body-text-line-height);
}

.howToApplyContainer {
    padding: 15px 5% 35px;
}

.howToApplyHeader {
    font-size: var(--header-text-font-size);
    line-height: var(--header-text-line-height);
}

.emailAddress {
    font-size: var(--small-header-text-font-size);
    line-height: var(--small-header-text-line-height);
}

.howToApplyDescription {
    font-size: var(--body-text-font-size);
    line-height: var(--body-text-line-height);
}

.emailButton {
    font-size: var(--enlarged-body-text-font-size);
    line-height: var(--enlarged-body-text-line-height);
    padding: 10px 20px;
    border-radius: 6px;
    color: var(--theme-gray-scale-15);
    margin-top: 15px;
    transition: filter 0.3s;
}

.emailButton:hover {
    filter: brightness(0.75);
}

.disclaimerContainer {
    padding: 0 5%;
    color: var(--theme-gray-scale-6);
}

@media (max-width: 555px) {
    .menuContainer {
        width: 100%;
        margin: 0;
    }
}